
.table-va-m {
    tr {

        td,
        th {
            vertical-align: middle;
        }
    }
}

.l-info {
    margin: 8px 0 4px 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;

    li {
        margin-right: 2rem;
        display: flex;
        align-content: center;

        i {
            margin-right: 6px;
        }

        span {
            display: inline-block;
            padding: 0px 8px;
            border-radius: 4px;
            margin-left: 5px;
            background-color: rgba(0, 0, 0, 0.08);
            color: #E64209;
        }
    }

}