.StudentCard {
    position: relative;
    width: 306px;
    height: 188px;
    background: #FFFFFF;
    border: 1px solid #184C7D;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4pt;

    .logo {
        position: absolute;
        width: 45px;
        right: 8px;
        top: 9px;
    }

    .header {
        padding: 6px 16px 0px 16px;

        svg {

            text-align: center;
            width: 230px;
        }
    }

    .student {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        align-items: flex-end;

        .profile {
            border: 1px dashed #184C7D !important;
            border-radius: 5px;
            width: 61px;
            height: 67px;
            margin-left: 0px;
            margin-top: -20px;
        }

        .id {
            width: 55%;
            padding-left: 17px;

            h6 {
                font-weight: 700;
                font-size: 12px;
                margin-bottom: 0;
            }

            p {
                font-size: 11px;
                color: #184C7D;
                margin-bottom: 0;
            }

        }

        .sign {
            text-align: center;
            margin-right: 10px;

            p {
                color: green;
                margin: 0;
                font-size: 10px;
                font-weight: bold;
            }
        }
    }

    .bio-data {
        margin-top: 5px;

        ul {
            margin: 0;
            display: flex;
            align-items: flex-end;
            gap: 10px;
            padding: 0;

            li {
                list-style: none;

                .data {
                    h6 {
                        color: #003970;
                        margin: 0;
                        font-size: 9.5px;
                        font-weight: 600;
                        white-space: nowrap;
                    }

                    p {
                        margin: 0;
                        font-size: 10px;
                        font-weight: 500;
                    }
                }

                &:nth-child(1) {
                    border-right: 1px dashed black;
                    padding-right: 10px;
                    width: 35%;
                }

                &:nth-child(2) {
                    border-right: 1px dashed black;
                    padding-right: 10px;
                    width: 30%;
                }

                &:nth-child(3) {
                    width: 30pt;
                    border-right: transparent;
                    padding-right: 10px;

                    .data {
                        p {
                            white-space: nowrap;
                        }
                    }
                }
            }

        }
    }
}