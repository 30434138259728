.library-list {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
	height: 324px;


	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5px;
		padding: 5px 0px;
		border-bottom: 1px solid rgb(225, 225, 225);

		&:last-child {
			border-bottom: none;
			margin-bottom: 0px;
		}

		.left {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			align-items: center;

			.icon {
				width: 45px;
				height: 45px;
				background-color: $primary;
				border-radius: 50%;
				align-items: center;
				text-align: center;
				display: flex;
				justify-content: center;

				span {
					color: white;
					font-weight: bold;
				}
			}

			.lable {
				margin-left: 12px;

				h5 {
					margin: 0;
					font-size: 1rem;
					font-weight: 700;
				}

				p {
					margin: 0;
					font-size: 1rem;
				}
			}
		}

		.right {
			font-weight: 700;
			margin-right: 12px;

			h4 {
				font-size: 1rem;
				margin: 0;
			}
		}
	}
}