.dashboard-rewards {
	background-color: $themeLight;
	margin-top: 1rem;
	padding: 2rem 0;
}

.reward-item {
	background-color: #FFF;
	padding: 1.5rem;
	border-radius: $radius;
	margin-bottom: 1.5rem;
	box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.05);
	border: 2px solid transparent;
	transition: all .5s ease;

	&:hover {
		border-color: $primary;
	}

	.icon {
		margin-bottom: 1rem;
		span {
			width: 40px;
			height: 40px;
			border-radius: $radius;
			@include gradient();
			color: #FFF;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 600;
		}
	}
	.info {
		p {
			margin: 0;
			@include textBlack(0.6);
			text-transform: uppercase;
			font-size: .9rem;
		}
		h2 {
			margin: 0;
		}
	}
}

