.size-3 {
  width: 300px;
}

.offcanvas-main-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    a {
      display: flex;
      align-items: center;
      padding: .8rem 1rem;
      font-size: .9rem;
      border-radius: .8rem;
      &:hover {
        @include bgSecondary(0.1);
      }

      img {
        width: 26px;
        margin-right: 1rem;
      }
    }
  }
}