//colors
$primary: #003970;
$secondary: #0095DF;
$green: #33CCCC;
$red: #E55C73;
$themeBlack: #293333;
$themeLight: #fbfbfb;
$themeDark: #191824;
$lightGray: #F8F8F8;
$warning: #F4B71E;
$gray: #EBEBEB;


$color-petrol: #549B8C;
$color-diesel: #BD9B16;

// fonts

$font-primary: 'Inter', sans-serif;
$font-secondary: 'Inter', sans-serif;


// general

$radius: .4rem;
