.wizard {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
	li {
		position: relative;
		margin: 0 1rem;
		z-index: 1;
		&::after {
			content: "";
			display: block;
			width: 114px;
			height: 3px;
			background-color: $themeLight;
			position: absolute;
			top: 50%;
			border-radius: 30px;
			right: -73px;
			z-index: -1;
			margin-top: -13px;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}

		a {
			text-align: center;
			display: inline-block;
			color: #2A2D34;
			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $themeLight;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin: auto;
				color: $themeBlack;
				font-size: 1.2rem;
				border: 5px solid #FFF;
			}
			.label {
				display: block;
				margin-top: .3rem;
				font-size: 13px;
			}

		}

		&.active {
			a {
				.icon {
					background-color: $primary;
					color: #FFF;
				}
			}

			&::after {
				background-color: $primary;
			}
			
			
		}
		&.completed {
			a {
				.icon {
					background-color: $green;
					color: #FFF;
				}
			}
			&::after {
				background-color: $green;
			}
			
		}
	}
}

@media screen and (max-width: 767px) {
	.wizard {
		overflow-y: scroll;
		li {
			&::after {
				top: 39%;
			}
			a {
				.label {
					line-height: 14px;
				}
			}
		}
	}
}