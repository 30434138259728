.wrapper {
    display: flex;
    width: 100%;
    background-color: $themeLight;

    .left-side {
        overflow-x: scroll;
        background: #f4f4f4;
        -webkit-box-shadow: inset -3px 0px 8px -4px rgb(0 0 0 / 10%);
        -moz-box-shadow: inset -3px 0px 8px -4px rgba(0, 0, 0, 0.1);
        box-shadow: inset -3px 0px 8px -4px rgb(0 0 0 / 7%);
        height: 100%;
        min-height: 100vh;
        width: 250px;
        position: fixed;
        left: 0;
        top: 50px;
    }

    .right-side {
        
        padding-left: 250px;
        padding-top: 60px;
        width: 100%;


        .content-header {
            display: flex;
            justify-content: space-between;
            background: $themeLight;
            box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
            align-items: center;
            padding: .8rem 2rem;

            h1 {
                font-size: 1.5rem;
                font-family: $font-secondary;
                margin: 0;
                color: $primary;
                font-weight: normal;
            }
        }

        > .content {

            padding: 2rem;
            position: relative;


        }
    }
}


@media screen and (max-width: 767px) {
    .wrapper {
        display: block;
        .left-side {
            transform: translateX(-100%);
        }
        .right-side {
            padding-left: 0;
        }
    }
}