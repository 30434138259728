.welcome-wrap {
	height: 100vh;
	background-color: $primary;
	position: relative;
	padding-top: 20px;
	.shape-1 {
		position: absolute;
		top: 0;
		right: 0;
	}
	.shape-2 {
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

.welcome-head {
	text-align: center;
	.logo {
		img {
			max-width: 120px;
		}

		margin-bottom: 20px;
	}

	p {
		margin: 0;
		font-weight: 300;
		color: rgba(255, 255, 255, .7);
	}
	h1 {
		margin: 0;
		color: #FFF;
		font-family: $font-secondary;
		font-size: 1.8rem;
	}
}

.welcome-card {
	border: 1px solid #dee2e6;
	position: relative;
	background-color: #f4f4f4;
	width: 1200px;
	margin: 1rem auto 0 auto;
	border-radius: $radius;
	.title {
		font-weight: normal;
		@include textBlack(1);
		margin-bottom: 1rem;
		font-size: 1.4rem;
		padding: 1rem;
		border-bottom: 1px solid rgba(0,0,0,0.3);
	}

	.welcome-content {
		padding: 1rem 3rem;
		h1 {
			font-size: 2rem;
			display: flex;
			align-items: center;
		}
		p {
			@include textBlack(.6);
		}

		.ready {
			display: flex;
			justify-content: space-between;
			margin-top: 1rem;
			align-items: center;
			h3 {
				font-size: 1.5rem;
				font-weight: normal;
				@include textBlack(0.5);
				margin: 0;
				span {
					@include textBlack(1);
				}
			}

			div {
				a {
					margin-top: 0.5rem;
					display: flex;
					align-items: center;
					@include textBlack(0.5);
					i {
						margin-left: 0.5rem;
					}
				}
			}
		}
	}
}

.auth-right {
	p {
		@include textBlack(0.4);
	}
}