.breadcrumb {
	
	margin: 0;
	padding: 0;

	li {
		a {
			color: $themeBlack;
		}

		&.active {
			@include textBlack(0.5);
		}

		
	}
}

.breadcrumb-item+.breadcrumb-item::before {
	@include textBlack(0.5);
}


