.lr-investments {
	padding: 2rem 0;

	.box {
		border: 1px solid $themeLight;
		padding: 2rem;
		border-radius: $radius;
		&.left {
			text-align: right;

			.icon-box-light {
				flex-direction: row-reverse;
				.icon {
					span {
						transform: scaleX(-1);
					}
				}
			}
		}

		.icon-box-light {
			 .copy-link {
			 	border: none;
				padding: 0.2rem 1rem;
				font-size: 0.8rem;
			 }
		}

		> h3 {
			font-size: 1.2rem;
		}
	}
}