.clander {
    width: 14.2857142857%;
    height: 150px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cecece;

    h3 {
        font-weight: 600;
        font-size: 2.5rem;
        margin: 0;
    }
}

.days_name {
    width: 14.2857142857%;
    height: 150px;
    display: inline-flex;
    border: 1px solid #cecece;
    flex-direction: column;
    position: relative;

    .num {
        text-align: right;

        span {
            text-align: right;
            font-weight: 600;
            font-size: 2.5rem;
            padding: 4px;
            color: $secondary;
        }
    }

    .detail {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: 6px;
        left: 4px;

        li {
            width: 46%;
            margin: 2px;
            padding: 2px;
            text-align: center;
            border-radius: 4px;
            color: white;
        }

        a {
            width: 100%;
            color: #ff3a33;
            &:hover{
                color: $secondary;
            }
        }

        .P {
            color: #ff3a33;
            border-radius: 5px;
            padding: 0.2rem;
            margin: 2px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .p {
            background-color: #0AD4A0;
        }

        .a {
            background-color: #F5466C;
        }

        .l {
            background-color: #F9AC49;
        }

        .lc {
            background-color: $secondary;
        }
    }
}