header {
    background-color: #FFF;
    padding: .6rem;
    transition: all 0.3s ease-in-out;
    z-index: 5;

    .navbar-general {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            &.user-logged-in {
                #dropdownUserLoggedIn {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    outline: transparent;
                    border: none;

                    &::after {
                        display: none !important;
                    }

                    .thumb {
                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                        }
                    }

                    .info {
                        padding-left: .6rem;
                        line-height: 1rem;

                        .user_name {
                            display: block;
                            font-size: 1rem;
                            color: $secondary;
                            font-weight: 600;
                        }

                        .role {
                            font-size: .8rem;
                        }
                    }
                }

                .dropdown-menu {
                    border: none;
                    top: 12px !important;
                    border-radius: 0;
                    box-shadow: 0px 0px 6px 0px #0000001f;

                    li {
                        .dropdown-item {
                            display: flex;
                            align-items: center;

                            i {
                                margin-right: .6rem;
                            }
                        }
                    }
                }
            }

            &.toggle-side-menu {
                padding: 0 1rem;

                a {
                    color: $secondary;
                    font-size: 1.7rem;
                }
            }

            .nav {
                .nav-item {
                    .nav-link {
                        color: $primary;

                        &:hover {
                            color: $secondary;
                        }
                    }
                }
            }
        }
    }
}

.navbar-module {
    background-color: $primary;
    padding: 0;

    .navbar-toggler {
        color: white;
        border: 1px solid;
        position: relative;

        .navbar-toggler-icon {
            color: white;
            background-image: url("~/src/assets/images/menu.svg");
        }

        &::before {
            content: "Menu";
            position: absolute;
            left: 53px;
            top: 7px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                color: #FFF;
                padding: 1rem 1.3rem;
                font-size: .9rem;
                border-right: 1px solid rgba(255, 255, 255, 0.2);

                &:hover,
                &:focus {
                    background-color: $secondary;
                }
            }

            &:last-child {
                .nav-link {
                    border-right: none;
                }
            }
        }
    }
}

.mobile-header {
    display: none;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.MenuSticky {
    position: fixed;
    top: 4.4rem;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 4 !important;

    .collapse {
        display: block;
    }
}

ul.navbar-nav.m-auto {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


@media screen and (max-width : 767px) {

    .navbar-module {
        padding: 7px;
    }

    .desktop-header {
        display: none;
    }


    .mobile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mobile-logo {
            width: 45px;
        }

        .toggle-side-menu {
            a {
                i {
                    font-size: 25px !important;
                    font-weight: 500;
                }
            }
        }
    }

    .nav-link {
        padding: 0.5rem 0.7rem;
    }
}