.icon-box-1 {
    background: rgba(0, 149, 223, 0.1);
    border-radius: 8px;
    padding: .8rem;
    position: relative;

    &.fs-sm {
        h4 {
            font-size: 1.6rem;
        }
    }

    .icon {
        position: absolute;
        right: 10px;
        top: 10px;

        i {
            color: $secondary;
            font-size: 1.6rem;
        }

        svg,
        img {
            max-width: 30px;
        }
    }

    p {
        font-weight: 400;
        font-size: .9rem;
        @include textBlack(0.5);
    }

    h4 {
        font-size: 2rem;
    }
}

.icon-box {
    background: rgba(0, 149, 223, 0.1);
    padding: 1.2rem 0.5rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;

    .icon {
        width: 70px;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            font-size: 2rem;
            border-radius: $radius;
            margin: auto;
        }
    }

    .content {

        padding-left: 10px;


        h5 {
            margin: 0;
            font-size: 1.2rem;
            opacity: 50%;
        }

        p {
            margin: 0;
            font-size: 1.3rem;
            font-weight: bold;
        }
    }
}

.icon-box-light {
    padding: 1.2rem .5rem;
    border-radius: $radius;
    background-color: #FFF;
    display: flex;
    align-items: center;

    &.border {
        border: 1px solid rgba(0, 0, 0, 0.3);
    }

    .icon {
        width: 70px;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            font-size: 2rem;
            border-radius: $radius;
            background-color: $themeLight;
            color: #FFF;
            margin: auto;
        }
    }

    .content {

        padding-left: 10px;

        p {
            margin: 0;
            font-size: .9rem;
            @include textBlack(0.5);
        }

        h2 {
            margin: 0;
            font-size: 1.5rem;
        }
    }

    &.bg-yellow {
        .icon {
            span {
                @include bgWarning(0.2);
                color: $warning;
            }
        }
    }

    &.bg-theme {
        .icon {
            span {
                @include bgPrimary(0.2);
                color: $primary;
            }
        }
    }


    &.bg-second {
        .icon {
            span {
                @include bgSecondary(0.2);
                color: $secondary;
            }
        }
    }

    &.bg-green {
        .icon {
            span {
                @include bgGreen(0.2);
                color: $green;
            }
        }
    }

    &.bg-red {
        .icon {
            span {
                @include bgRed(0.2);
                color: $red;
            }
        }
    }
}

.list-info-boxes {
    margin: 0;
    padding: 0;
    list-style-type: none;


    &.items-2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            width: 46%;

            &:last-child {
                margin-bottom: 0.8rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }


        }
    }

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 0.8rem;
        margin-bottom: 0.8rem;
        padding-right: 1rem;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

        .left {
            display: flex;
            align-items: center;

            p {
                margin: 0;
            }

            .icon {
                width: 55px;

                span {
                    width: 42px;
                    height: 42px;
                    background: rgba(0, 149, 223, 0.1);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $secondary;
                    font-weight: bold;
                }
            }
        }

        .right {
            font-weight: 700;
            font-size: 1.2;

        }
    }

}







@media screen and (max-width : 767px) {
    .icon-box-1 {
        display: flex;
        margin: 10px 0px;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 1.4rem;
            font-weight: 600;
            margin: 0;
        }

        h4 {
            font-size: 2.1rem;
            margin: 0;
            margin-right: 3rem;
        }
    }

    .list-info-boxes {
    
        &.items-2 {
    
            li {
                width: 100%;
    
                &:last-child {
                    margin-bottom: 0.8rem;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
    
    
            }
        }
    }

}