body {
	font-family: $font-primary;
	background-color: $gray;
	color: $themeBlack;
	padding-bottom: 3rem;
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $secondary;
	}
}


@media screen and (max-width: 1200px) {

	body,
	* {
		font-size: 14px !important;
	}
}