.birthday-list {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
	height: 324px;


	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5px;
		padding: 5px 0px;
		border-bottom: 1px solid rgb(225, 225, 225);

		&:last-child {
			border-bottom: none;
			margin-bottom: 0px;
		}

		.left {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			align-items: center;

			.icon {
				width: 60px;
				height: 60px;
				border-radius: 50%;

				img {
					border-radius: 50%;
				}
			}

			.lable {
				margin-left: 12px;
				line-height: 20px;

				h5 {
					margin: 0;
					font-size: 13px;
					font-weight: bold;
				}

				p {
					margin: 0;
					font-size: 13px;
				}
			}
		}

		.right {
			font-weight: 700;
			margin-right: 12px;

			h4 {
				font-weight: normal;
				font-size: 1rem;
				margin: 0;
			}
		}
	}
}
