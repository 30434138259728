.card-height {
    height: 70vh;
    overflow-x: hidden;
    display: flex;
    

    .sms-section {
        position: absolute;
        bottom: 79px;
        right: 1rem;
    }
}
.sms-recode {
    margin: auto;

    p {
        background: #E4E9F2;
        color: #8D8D8D;
        margin: 0;
        border-radius: 50px;
        padding: 10px 20px;
        display: inline-block;
    }
}
.chat-style {
    background: #F8F8F8;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    padding:1rem;
}

.sms-text {
    padding-right: 1rem;
    padding-top: 0.3rem;
    text-align: end;

    .label-value {
        background-color: $secondary;
        border-radius: 20px;
        border-bottom-left-radius: 0 !important;
        color: white;
        padding: 1rem;
        text-align: left
    }

    .time {
        span {
            color: #B8B8B8;
            font-size: 13px;
        }

        i {
            color: $secondary;
        }
    }
}

.gray {
    background: #E4EAF1 !important;
    color: black !important;
}

.send-input {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    display: block;
    width: 100%;
    padding: .9rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:focus-visible {
        border: none;
    }
}

.sent-button {
    position: absolute;
    bottom: 20px;
    right: 30px;
    border-radius: 20px;
    border-top-left-radius: 0 !important;
    color: white;
    background: #D9D9D9;
}
