
.form-floating {
	.chosen-container-single {
		.chosen-single {
			height: 58px !important;
			background: none !important;
			background-color: #FFF !important;
			box-shadow: none !important;
			padding: 24px 0 0 13px;
		}
	}
}
