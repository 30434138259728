.report-header {
	padding-bottom: 8px;
	border-bottom: 1px solid;
	margin-bottom: 8px;
	// 
	


	table {
		
		 tr {
			 td {
				border-collapse: collapse;
				
				.logo {
					display: flex;
					align-items: center;
					img {
						width: 300px;
						flex-shrink: 0;
						height: auto;
					}

					.company {
						padding-left: 14px;
						h3 {
							font-family: $font-secondary;
							margin: 0;
							font-size: 18pt;
							font-weight: bold;
						}
						p {
							margin: 0;
							font-size: 9pt;
						}
					}
				}

				.header-right {
					text-align: right;
					tr {
						td {
							&:last-child {
								width: 30px;
							}

							i {
								font-size: 12pt;
							}

							font-size: 10pt;
						}
					}
				}
			}
		}
	}

	
}

@media print {
	// .report-header {
	// 	position: fixed;
	// 	top: 10px;
	// 	width: 750px;

	// }

	.page {
		&.legal {
			&.landscape {
				.report-header {
					width: 350mm;
				}
			}
			
		}
		&.a4 {
			&.landscape {
				.report-header {
					width: 1000px;
				}
			}
			
		}
	}
}