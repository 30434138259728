.login-wrap {
	position: relative;
	> img {
		position: absolute;
		left: 0;
		top: -100px;
	}

	.login-box {
		background: #FFFFFF;
		border: 1px solid rgba(54, 61, 77, 0.1);
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
		border-radius: 16px;
		position: relative;
		padding: 4rem;

		.logo {
			margin-bottom: 2rem;
			img {
				max-width: 300px;
			}
		}

		h2 {
			font-size: 1.4rem;
			font-weight: bold;
		}

		.form-group {
			position: relative;
			.form-control {
				min-height: 54px;
				padding-left: 56px;
				&:focus {
					border-color: $secondary;	
					box-shadow: none;

					~ i span {
						color: $secondary;
					}
				}
			}

			i {
				position: absolute;
				top: 10px;
    			left: 14px;
    			font-size: 1.5rem;
    			@include textBlack(0.4);
			}
			span {
				position: absolute;
				top: 10px;
    			right: 14px;
    			font-size: 1.5rem;
    			@include textBlack(0.4);
				cursor: pointer;
				&:active{
					color: $secondary;
				}
			}
			.ri-eye-off-line{
				color: $secondary;
			}
		}
	}
}

@media screen and (max-width: 992px) {
	.login-wrap {
		position: relative;
		> img {
			left: 24px;
			top: -47px;
			width: 335px;
		}
	
		.login-box {
			padding: 1.5rem;
	
			.logo {
				margin-bottom: .6rem;
				img {
					max-width: 215px;
				}
			}
	
			h2 {
				font-size: 1.2rem;
				margin-bottom: 10px !important;
			}
	
			.form-group {
				.form-control {
					min-height: 38px;
					padding-left: 45px;
				}
	
				i {
					top: 5px;
					left: 14px;
					font-size: 1.2rem;
				}
				span {
					top: 7px;
					right: 14px;
					font-size: 1.1rem;
				}
				
			}
		}
	}
	
}
// @media screen and (max-width: 767px) {
// 	.login-wrap {
// 		> img {
// 			width: 100%;
// 			top: -50px;
// 		}
// 		.login-box {
// 			padding: 2rem;
// 			.logo {
// 				img {
// 					max-width: 240px;
// 				}
// 			}
// 		}
// 	}
// }