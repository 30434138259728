.imageBoxwrap {
    
    width: 300px;
    &.size-stamp {
        width: 865px;
        .imageBox {
            width: 865px;
            height: 1200px;
            .thumbBox {
                width: 794px;
                height: 1124px;
                top: 11%;
                left: 16%;
            }
        }
    }
}   

.imageBox {
    width: 300px;
    height: 300px;
    overflow: hidden;
    position: relative;
    
}

.tools {
    margin-top: 20px;
}

.tools span {
    display: inline-block;
    padding: 5px 20px;
    background-color: #f40;
    color: #fff;
    cursor: pointer;
    margin-bottom: 5px;
    margin-right: 5px;
}

.clearfix {
    *zoom: 1;
}

#crop {
    max-width: 300px;
}

.jactions {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    width: 300px;
    z-index: 12;
    bottom: 80px;
    display: flex;
    justify-content: center;

    li {
        margin: 4px 5px;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:#FFF;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 1.5rem;
            border: 1px solid $secondary;
        }
    }
}

.cropPoint {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
}

.upload-wapper {
    position: relative;
    float: left;
    height: 26px;
    line-height: 26px;
    width: 132px;
    background-color: #f40;
    color: #fff;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
}



#visbleCanvas {
    position: absolute;
    top: 0;
    left: 0;
}

.thumbBox {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-left: -100px;
    box-sizing: border-box;
    border: 1px solid #666666;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
    background: none repeat scroll 0% 0% transparent;
}

.cropped-stamp {
    text-align: right;
    img {
        width: 200px;
    }
}