.widget {
    background-color: #FFF;
    border: 1px solid #E0DFDC;
    border-radius: $radius;
    padding: 20px;
    margin-bottom: 20px;

    .widget-title {
        font-size: 16px;
        font-weight: bold;
        color: $themeBlack;
        position: relative;

        .action {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .widget-body {
        padding-top: 20px;
    }
}