.dashboard {
	margin-top: 8rem;

}

.dashboard-buttons {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: .9rem;

	li {
		width: 193.675px;

		a {
			background: #FFFFFF;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 150px;
			border: 1px solid transparent;

			&:hover {
				border-color: $secondary;
			}

			img {
				margin-bottom: 1rem;
			}

			span {
				font-weight: 600;
			}
		}
	}
}

@media screen and (max-width:767px) {
	.dashboard-buttons {
		margin-bottom: 20px;

		li {
			width: 150.675px;

			a {
				min-height: 144px;
			}
		}
	}
}