

.info-box {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: $radius;
    padding: 1rem;
    margin-bottom: 1rem;

    p {
        margin: 0;
        @include textBlack(0.7);
        font-size: 1rem;
    }

    h4 {
        margin: 0;
        font-size: 1rem;
        font-weight: 700;
    }
}


.dash-icon-box {
    border-radius: $radius;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-height: 100px;
    background-color: $lightGray;
    transition: transform 0.8s ease;
    border: 1px solid transparent;



    .icon {
        position: relative;
        
        flex-basis: 70px;
        span {
            display: block;
            position: absolute;
            bottom: -65px;
            right: -26px;

            i {
                @include textBlack(0.5);
                font-size: 90px;

            }
        }

        img {
            width: 60px
        }
    }

    .info {
        flex-basis: 70%;
        padding-left: 1rem;
        p {
            font-weight: 300;
            font-size: 1rem;
        }
        h4 {
            font-size: 1.2rem;
            font-weight: normal;
        }
    }

    &.box-sm {
        min-height: 100px;

        h1 {
            font-size: 24px;
        }
    }

    &:hover {
       border-color: $secondary;
    }

    &.bg-light {
        border: 1px solid #e7e7e7;
        box-shadow: 0 10px 35px rgb(38 42 76 / 10%);

        h1 {
            color: rgba(0, 0, 0, 0.8);
        }

        p {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    

    .wallet-address {
        font-size: 1.2rem;
        font-weight: normal;
        overflow-wrap: anywhere;
        max-width: 500px;
    }

    h1 {
        font-size: 30px;
        font-weight: 500;
        color: $themeBlack;
        margin: 0 0 10px 0;
    }

    p {
        font-size: 14px;
        @include textBlack(0.5);
        font-weight: 500;
        margin: 0;

    }

    .action {
        text-align: center;
        line-height: 20px;
        position: absolute;
        right: 10px;
        width: 130px;
        font-weight: bold;
    }
}