.hero {
	position: relative;
	// background-image: url("../images/backgrounds/landing-bg.svg");
	background-size: contain;
	background-repeat: no-repeat;
	min-height: 100vh;
	.video-section {
		overflow: hidden;
		height: 100vh;
		position: relative;

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .7);
		}
	}
	
	.hero-content-wrap {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.hero-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
		position: relative;
		justify-content: center;
		p {
			font-style: normal;
			font-weight: 300;
			font-size: 1rem;
			color: #FFF;
			margin-bottom: 0;
		}

		h1 {
			font-weight: bold;
			font-size: 3.688rem;
			color: #FFF;
			font-family: $font-secondary;
		}

		h4 {
			font-weight: 300;
			font-size: 1.3rem;
			color: rgba(255, 255, 255, .7);
		}

		.button {
			margin-top: 2rem;
                &:hover {
                    border-color: #FFF;
                    color: #FFF;
                }
            }
	}

	.scroll-more {
		color: #FFF;
		display: flex;
		align-items: center;
		position: absolute;
		bottom: 5%;
		i {
			margin-right: 0.2em;
			color: $themeLight;
			font-size: 1.3rem;
		}
	}
}


.hero-banners {
	@include gradient();
	min-height: 400px;
	.banner-content {
		h3 {
			color: #FFF;
			font-family: $font-secondary;
		}
		p {
			color: rgba(255, 255, 255, 0.5);
			font-size: .9rem;
		}
	}
}


@media screen and (max-width: 992px) and (min-width: 768px) {
	.hero {
		padding-top: 6rem;
		.hero-content {
			p {
				margin-bottom: 0.188rem;
			}
			h4 {
				font-size: 1.9rem;
			}
		}
	}
}


@media screen and (max-width: 991px) {
	.hero {
		height: auto;
		.scroll-more {
			display: none;
		}
	}
}

@media screen and (max-width: 767px) {
	.hero {
		padding: 2rem 0 0 0;
		
		.hero-content {
			h1 {
				font-size: 3rem;
			}
			p {
				font-size: 1.3rem;
			}
			h4 {
				font-size: 1.41rem;
			}
		}

		
	}
}