.simple-section {
    padding: 1rem 0;
    position: relative;
}

.section-title {
    font-size: 2.27rem;
    font-weight: 600;
    color: $themeBlack;
    text-align: center;
    position: relative;
    margin-bottom: 3rem;
    font-family: $font-secondary;

    &::after {
        content: "";
        display: block;
        width: 80px;
        height: 3px;
        background-color: $primary;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -12px;
        margin: auto;
        border-radius: 1rem;
    }

    &.title-sm {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    // &::before {
    //     content: "";
    //     display: block;
    //     width: 30px;
    //     height: 38px;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: -35px;
    //     margin: auto;
    //     animation: move4 5s infinite linear;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    // }





    &.title-left {
        text-align: left;

        &::after {
            margin-left: 0;
        }
    }

    &.title-white {
        color: #FFF;

        &::after {
            background-color: #FFF;
        }


    }
}

.choose-photo {
    label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: $radius;
        border: 1px dashed rgba(0, 0, 0, 0.4);
        height: 300px;
        cursor: pointer;

        i {
            font-size: 5rem;
            color: rgba(0, 0, 0, 0.4);
        }

        input {
            position: absolute;
            opacity: 0;
        }
    }
}

.text-icon {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-weight: 500;

    i {
        padding-left: 8px;
        font-weight: 600;
    }
}

.sky {
    color: $secondary;
}

.pink {
    color: #E55C73;
}

.scrollbar {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: .6rem;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, .1);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(182, 182, 182);
    }
}

.exam-info {
    i {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.2);
    }
}


.text-red {
    color: $red;
}

.text-green {
    color: $green;
}


.bg-gray {
    background-color: $gray;
}

.content-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}


.profile-wrap .card-title {
    background-color: $lightGray;
    padding: 1rem;
    margin-bottom: 1rem;
}

.dashboard-title {
    font-size: 1.8rem;
    font-family: $font-secondary;
    font-weight: 500;
    color: $themeBlack;
    position: relative;
    margin-bottom: 2rem;

    &::after {
        content: "";
        display: block;
        width: 80px;
        height: 3px;
        background-color: rgba(0, 0, 0, .2);
        position: absolute;
        left: 0;
        bottom: -12px;
        border-radius: 1rem;
    }
}

.section-sub-title {
    font-size: 1.175rem;
    text-align: center;
    margin: 0;
    font-weight: 300;
    @include textBlack(0.5);

    &.title-left {
        text-align: left;
    }
}

.section-shapes {
    .shape {
        position: absolute;

        &.shape-1 {
            left: 2%;
        }

        &.shape-2 {
            right: 2%;
        }

        &.shape-3 {
            right: 0;
            bottom: 0;
        }
    }
}

.list-bullets {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;

    li {
        @include textBlack(0.5);
        font-size: 1.125rem;
        position: relative;
        padding-left: 1.4rem;

        &::before {
            font-family: remixicon !important;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            content: "\EB7C";
            position: absolute;
            left: 0;
            top: 0;
            color: $primary;
            font-size: .7rem;
            top: .4rem;
        }
    }

    &.link-white {
        li {
            color: #FFF;

            &::before {
                color: #FFF;
            }
        }
    }
}

.text-right {
    text-align: right;
}

.link-icon-button {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 13px 0px;
    color: $secondary;

    :hover {
        color: $secondary;
    }

    i {
        padding-right: 10px;
        font-size: 1.3rem;
    }

    span {
        color: $themeBlack;
    }
}


/* Alert CSS */
.alert {
    padding: 20px;
    background-color: #f44336;
    color: white;
    opacity: 1;
    transition: opacity 0.6s;
    margin-bottom: 15px;
    position: fixed;
    width: 42%;
    min-width: 25%;
    z-index: 1050;
    right: 0;
    bottom: 0;
    font-size: 15px;
}

.alert.success {
    background-color: #4CAF50;
}

.alert.info {
    background-color: #2196F3;
}

.alert.warning {
    background-color: #ff9800;
}

.alertclosebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.alertclosebtn:hover {
    color: black;
}


.student-card {

    .card-header {
        background-color: $secondary;
        height: 120px;
        display: flex;
        align-items: flex-end;
        position: relative;

        .profile {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 1px solid;
            margin-bottom: -66px;
            display: inline-block;
            background-color: rgb(223, 223, 223);

            img {
                width: 150px;
                height: 150px;
                border-radius: 50%;
            }
        }

        .user-name {
            margin-left: 30px;

            h1 {
                color: white;
                margin: 0;
            }

            p {
                color: white;
                margin: 0;
            }
        }

        b {
            position: absolute;
            top: 15px;
            right: 20px;
            color: white;
        }

    }

    .menu {
        margin-left: 167px;
        margin-top: -10px;

        .outline {
            border: 1px solid $secondary;
            color: $secondary;
            border-radius: 50px;
            padding: 5px 20px;
            display: inline-block;
            margin: 10px;
        }
    }

    .profile-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        list-style: none;
        margin: 25px 0;
        gap: 10px 0;

        li {

            &:nth-child(odd) {
                color: black;
                font-weight: bold;
                min-width: 20%;
            }

            &:nth-child(even) {
                color: gray;
                font-weight: 500;
                min-width: 30%;
            }


        }
    }

    .location {
        margin-top: 1.3rem;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 1rem;
        font-weight: 500;
        font-size: 15px;
        width: 100%;
        display: flex;


        i {
            font-size: 1.6rem;
            margin-right: 10px;
        }

        p {
            margin: 0;
            margin-top: 0.5rem;
        }
    }
}

.staff-card {

    // margin-top: 40px;

    .user-profile {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .profile {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 1px solid;
            background-color: rgba(0, 0, 0, 0.10);

            img {
                width: 150px;
                height: 150px;
                border-radius: 50%;
            }
        }

        h4 {
            color: $secondary;
            font-weight: bold;
            margin: 0;
            margin-top: 10px;
        }

        p {
            margin: 0;
            font-weight: 500;
        }
    }



    .location {
        margin-top: 15px;
        padding: 10px;
        background-color: rgb(239, 239, 239);
        text-align: center;
        font-size: 14px;
        border-radius: 5px;

        i {
            font-size: 30px;
            margin-bottom: 10px;
        }
    }

    .profile-detail {
        border-bottom: 1px dashed rgb(211, 211, 211);
        margin-bottom: 11px;

        h6 {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
        }

        p {
            font-size: 16px;
            margin: 0;
        }
    }
}

canvas#mydoughnutChart {
    height: 400px !important;
    margin: auto;
    width: 400px !important;
}

canvas#mybarChart {
    height: 400px !important;
    margin: auto;
    width: 400px !important;
}

.print-icon {
    color: black;
    display: block;
    text-align: end;
    transition: all 0.3s ease;

    &:hover span {
        border: 1px solid $secondary;
        color: $secondary;
        background-color: transparent;
        transform: scale(1.1);
        /* Adding a scale animation on hover */
    }

    span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 30px;
        height: 30px;
        color: white;
        background-color: $secondary;
        margin-right: 10px;
        border-radius: 50%;
        border: transparent;
        transition: all 0.3s ease;
        /* Adding a transition for smooth animation */

    }
}


.counting {
    span {
        height: 30px;
        min-width: 30px;
        border-radius: 50px;
        background-color: #003970;
        color: white;
        display: inline-flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
    }
}

.change-password {
    .form-group {
        position: relative;

        span {
            cursor: pointer;
            position: absolute;
            bottom: 5px;
            right: 9px;
            font-size: 17px;

            &.ri-eye-off-line {
                color: gray;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .section-title {
        font-size: 2rem;
    }

    .dash-head {
        .breadcrumb {
            justify-content: center;
            border-bottom: 1px solid rgba(255, 255, 255, .3);
            padding-bottom: 1rem;
        }
    }

    .body-wrap {
        padding-top: 5rem;
    }

    .hide-on-mobile {
        display: none;
    }
}



.alert {
    border-radius: $radius;
}