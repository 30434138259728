.page {
    box-sizing: border-box;
    width: 210mm;
    min-height: 277mm;
    padding: 0;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 5mm rgba(0, 0, 0, 0.1);
    transform-origin: 50% 50%;
    color: #000;
    page-break-after: always;

    &:last-child {
        page-break-after: auto;
    }

    &.legal {
        width: 215.9mm;
        height: 355.6mm;
        &.landscape {
            width: 355.6mm;
            height: 215.9mm;
        }
    }

    &.a4 {
        &.landscape {
            width:  297mm;
            min-height:  210mm;
        }
    }

    .content {
        box-sizing: border-box;
        width: 100%;
        min-height: 277mm;
        padding: 4mm;
        margin: 0;
        background-color: #fff;

        table {
            thead {
                display: table-header-group;
               
            }
        }
    }
}


.page-break {
    page-break-before: always;
}

.page-break-after {
    page-break-after: always;
}

.table-print.table-sm {
    border-color: #000;
    td,th {
        font-size: 11px;
        padding: 4px !important;
        vertical-align: middle;
        color: #000;
    }
}

.table-payslip {
    thead {
        td {
            font-weight: bold;
        }
    }
    td {
        border: 1px solid;
        padding: 3px;
    }
}



@media print {
    body {
        background-color: #FFF;
    }
    .page {
        box-shadow: none;
    }

    .print-main-table {
        > thead {
            td {
               padding: 0; 
            }
        }
        > tbody {
            td {
                padding: 0;
            }
        }
    }
    // tr{
    //     border: 1px solid black;
    // }
   
    .content {
        // top: 80px;
        // position: relative;
        table {
            thead {
                display: table-header-group !important;
                
            }
        }
    }
}