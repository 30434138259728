.pagination {
    .page-item {
        .page-link {
            color: $primary;
        }

        &.active {
            .page-link {
                background-color: $primary;
                border-color: $primary;
                color: #FFF;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .pagination-wrap {
        .d-flex {
            justify-content: space-between;
            .ms-2{
                margin:0 0 10px !important;
                
            }
        }
        .pagination {
            justify-content: center !important;
        }
    }
}