.add_class_options {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
        width: 50%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding: .5rem;
        justify-content: space-between;

        &:hover {
            background-color: rgb(0,0,0,0.02)
        }

        .sr {
            width: 10%;
            font-size: .9rem;
            display: none;
            color: rgba(0,0,0,0.5);
        }

        .type {
            font-size: .9rem;
            width: 65%;
            font-weight: 500;
            padding-right: 10px;
        }

        .input {
            width: 20%;

            .form-control {
                text-align: center;
                padding: 0;
            }
        }
    }
}
