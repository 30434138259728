.card {
    border-radius: $radius;
    // border: none;

    .card-title-dash {
        border-bottom: 1px solid rgba(0, 0, 0, .2);
        padding-bottom: 1rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1rem;
    }
}

.card-title {
    margin-bottom: .9rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, .6);
    font-weight: 600;
    position: relative;

    .card-action {
        position: absolute;
        right: 0;
        top: 0;

        .button {
            padding: 0.1rem 0.8rem;
            font-size: .8rem;
        }
    }
}

.equal-cards {
    .card {
        min-height: 120px;
    }
}

.card-title-2 {
    position: relative;
    font-family: $font-secondary;
    font-size: 1.2rem;
    display: flex;
    padding-left: 2rem;

    i {
        margin-right: 0.4em;
        color: $secondary;
        position: absolute;
        left: 0;
        top: -3px;
        font-size: 1.5rem;
    }
}

.pink-value {
    color: #E55C73;
    background-color: #FADEE3;
    border-radius: 20px;
    text-align: center;
    display: flex;
    font-weight: 500;
    justify-content: center;
    padding: 0.2rem;
}

.blue-value {
    color: $secondary;
    background-color: #CCEAF9;
    border-radius: 20px;
    text-align: center;
    display: flex;
    font-weight: 500;
    justify-content: center;
    padding: 0.2rem;
}

.radio-button {
    margin-top: 0.5rem;

    .radio {

        position: relative;

        .rabio-btn {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
            cursor: pointer;
            margin: 3px;
            border-radius: 50%;
            padding: 4px;
            width: 45px;
            height: 45px;
            background: #F8F7FE;
            color: black;
            font-size: 14px;
            transition: all 100ms linear;
            display: inline-flex;
            transition: all 100ms linear;
            justify-content: center;
            align-items: center;

            &:checked {
                color: #fff;
            }

            &:before {
                content: attr(label);
                display: inline-block;
                text-align: center;
                width: 100%;
            }
        }

        .btn-1 {
            &:checked {
                background-color: #0AD4A0;
            }
        }

        .btn-2 {
            &:checked {
                background-color: #F5466C;
            }
        }

        .btn-3 {
            &:checked {
                background-color: #F9AC49;
            }
        }

        .btn-4 {
            &:checked {
                background-color: $secondary;
            }
        }

        .btn-5 {
            &:checked {
                background-color: #B700F8;
            }
        }

        .btn-6 {
            &:checked {
                background-color: #99F600;
            }
        }

        .btn-7 {
            &:checked {
                background-color: #D2BE3E;
            }
        }

        .btn-8 {
            &:checked {
                background-color: $primary;
            }
        }
    }
}

.attendence {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 292px;

    img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-bottom: .7rem;
        border: 1px solid $secondary;
    }

    h4 {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 600;
    }

    p {
        margin: 0;
        color: #00000070;
        font-size: .9rem;
    }

    span {
        color: $secondary;
    }
}

.staff-attendence {
    padding: 0px !important;

    .rabio-btn {
        border-radius: 2px !important;
        padding: 4px !important;
        margin: 2px !important;
        width: 50px !important;
        height: 28px !important;
    }
}

.a-card {
    &:hover {
        color: $red;
    }
}

@media screen and (max-width:1200px) {
    .mobile-card {
        height: 200px;
    }
}